
import { Component, Vue } from 'vue-property-decorator';
import { Gopage } from '@/mixins/gopage';

@Component({
  components: {},
  mixins: [Gopage],
})
export default class BottomModule extends Vue {
  /**
   * @desc Vue 创建数据生命周期，获取验证码
   * @param1 暂无
   * @returns 暂无
   */
  created() {}

  // 调用拨号功能
  callphone(phonenumber: any) {
    window.location.href = 'tel://' + phonenumber;
  }

  openAdress() {
    window.open('http://uri.amap.com/marker?position=120.125533,30.272903&name=黄龙时代广场B座&coordinate=gaode&callnative=1', '_blank');
  }
  //定义一个方法获取注册邮箱是属于哪个邮箱的
  goToEmail(email: any) {
    const hash: any = {
      'qq.com': 'http://mail.qq.com',
      'gmail.com': 'http://mail.google.com',
      'sina.com': 'http://mail.sina.com.cn',
      '163.com': 'https://mail.163.com',
    };
    //获取注册邮箱@后面的地址
    const _email: any = email.split('@')[1].toLowerCase();
    if (hash.hasOwnProperty(_email)) {
      return hash[_email];
    } else {
      return false;
    }
  }
  goToEmaulAddress(email: any) {
    console.log('asdasdas');
    const address: any = this.goToEmail(email);
    if (address) {
      window.open(address, '_blank');
    } else {
      return;
    }
  }

  goToBusinessRegistration() {
    this.$router.push('/registration');
  }
}
